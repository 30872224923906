import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, PageHeader, Icon } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { openNotificationWithIcon } from '../common/NotificationToster';
import {
  apiParams,
  transformAddress,
  DEFAULT_LOCATION,
  DEFAULT_LATLONG,
  MAP_REFRESH_DELAY
} from './AutoFillHandler';
import { PageLayout } from '../Layout/page-layout';
import { getLocationFromBrowser } from '../../utils/location';
import { Maps } from '../Maps/Maps';
import { ConfirmModal } from '../common/ConfirmModal';
import { FarmContainer, StyledHeader } from './CreateEditFarmComponent.style';
import { iff } from '../../utils/iff';
import { Loader } from '../Loader/Loader';
import { isEmptyStr } from '../../utils/common-methods';
import timezones from './timezones.json';
import { AppConstants } from '../../utils/app.constants';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { getFieldsByPropertyId } from '../../actions/fields';
import { URL_CONSTANTS } from '../../utils/history';
import {
  StyledInput,
  StyledSaveButton,
  StyledDiscardButton,
  StyledAlert
} from '../../utils/styles.theming';

let autocomplete;

const CreateEditFarmComponent = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [fields, setFields] = useState([]);
  const { t } = useTranslation();
  const getTimeZoneById = (id) => {
    const foundTime =
      timezones.find((time) => {
        return time.utc.indexOf(id) >= 0;
      }) || {};
    return foundTime.text;
  };

  const setAddressToForm = (place) => {
    let finalAddress = transformAddress(place);
    let lat;
    if (typeof place.geometry.location.lat().toString() === 'string') {
      lat = Number(
        place.geometry.location
          .lat()
          .toString()
          .slice(
            0,
            place.geometry.location
              .lat()
              .toString()
              .indexOf('.') + 9
          )
      );
    }
    let lng;
    if (typeof place.geometry.location.lng().toString() === 'string') {
      lng = Number(
        place.geometry.location
          .lng()
          .toString()
          .slice(
            0,
            place.geometry.location
              .lng()
              .toString()
              .indexOf('.') + 9
          )
      );
    }
    props.form.setFieldsValue({
      [apiParams.zipCode]: finalAddress.postal_code,
      country: finalAddress.country,
      state: finalAddress.administrative_area_level_1,
      city: finalAddress.locality || finalAddress.political,
      address: finalAddress.address,
      lat: lat,
      long: lng,
      [apiParams.timeZone]: get(finalAddress, 'timeZone[0].text')
    });
  };

  /**
   * When the user selects an address from the dropdown, populate the address fields in the form.
   * This function to get the place details from the autocomplete object.
   */
  const fillInAddress = () => {
    const place = autocomplete.getPlace();
    setAddressToForm(place);
  };

  const setLatLongToAutocomplete = () => {
    autocomplete.addListener('place_changed', fillInAddress);
    let geocoder = new window.google.maps.Geocoder();
    let latlng = {
      lat: Number(props.form.getFieldValue('lat')) || DEFAULT_LATLONG,
      lng: Number(props.form.getFieldValue('long')) || DEFAULT_LATLONG
    };
    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === 'OK') {
        setAddressToForm(results[0]);
      }
    });
  };
  window.initAutocomplete = () => {
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('CreateEditProperty_address'),
      { types: ['geocode'] }
    );
    if (props.farmId) {
      autocomplete.addListener('place_changed', fillInAddress);
    }
  };

  const { property, farmId } = props;
  const redirectToPropertiesPage = () =>
    props.history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: props.orgId }));
  const redirectToEditFieldsPage = () =>
    props.history.push(
      URL_CONSTANTS.ALL_FIELDS({ orgId: props.match.params.id, farmId: props.match.params.farmId })
    );

  // when we redirect to this page from other page, window.google is available
  useEffect(() => {
    if (window.google) {
      window.initAutocomplete();
    }
    const getDetails = async () => {
      const propertyRes = await getFieldsByPropertyId(props.match.params.farmId);
      let allFieldsRes = propertyRes.data.content || [];
      setFields(allFieldsRes);
    };
    props.fetchOrgs().then((response) => {
      if (farmId) {
        getDetails();
      }
    });
    if (farmId) {
      if (!property) {
        // if data is not available, redirect to edit issue
        return redirectToPropertiesPage();
      }
      setTimeout(() => {
        props.form.setFieldsValue({
          name: property.name,
          [apiParams.zipCode]: property.zip_code,
          country: property.country,
          state: property.state,
          city: property.city,
          address: property.address,
          [apiParams.timeZone]: getTimeZoneById(property.time_zone),
          lat: property.reference_point.coordinates[0],
          long: property.reference_point.coordinates[1]
        });
      }, MAP_REFRESH_DELAY);
    } else {
      (async () => {
        try {
          const location = await getLocationFromBrowser();
          props.form.setFieldsValue({
            lat: location.latitude,
            long: location.longitude
          });
        } catch (ex) {
          props.form.setFieldsValue({
            lat: DEFAULT_LOCATION.LAT,
            long: DEFAULT_LOCATION.LONG
          });
        } finally {
          setLatLongToAutocomplete();
        }
      })();
      props.form.setFieldsValue({
        name: ''
      });
    }
    return () =>
      false &&
      window.google?.maps?.event?.clearListeners(autocomplete, 'place_changed');
  }, []);

  const { getFieldDecorator } = props.form;
  const onFormSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values[apiParams.orgId] = props.orgId;
        values.id = props.farmId;
        values[apiParams.referencePoint] = {
          type: 'Point',
          coordinates: [values.lat, values.long]
        };
        values[apiParams.timeZone] = 'America/Belize';
        if (props.farmId) {
          try {
            await props.saveEditedProperty(values);
            openNotificationWithIcon('success', t('Changes saved successfully'), 'bottomRight');
            setIsDirty(false);
          } catch (ex) {
            setErrorMessage(getErrorMessageByCode(get(ex, 'response.data.code')));
          }
        } else {
          try {
            const response = await props.saveCreatedProperty(values);
            props.history.push(
              URL_CONSTANTS.CREATE_FIELDS({ orgId: props.orgId, farmId: response.data.id })
            );
          } catch (ex) {
            setErrorMessage(getErrorMessageByCode(get(ex, 'response.data.code')));
          }
        }
      }
    });
  };

  const onBackButtonClicked = () => {
    if (isDirty) {
      setShowModal(true);
      setIsEditClicked(false);
    } else {
      redirectToPropertiesPage();
    }
  };
  const handleEditFieldsClick = () => {
    if (isDirty) {
      setShowModal(true);
      setIsEditClicked(true);
    } else {
      redirectToEditFieldsPage();
    }
  };

  const fieldTouched = () => {
    setIsDirty(true);
  };
  return PageLayout({
    content: (
      <div>
        {iff(props.isLoading, <Loader />)}
        <Row>
          <Col span={8}>
            <StyledHeader>
              <Icon
                data-testid="edit-farm-back-btn"
                onClick={onBackButtonClicked}
                className='back-button'
                type='left'
                style={{ fontSize: '14px' }}
              />
              <PageHeader id="CreateEditPropertyHeader" data-testid="page-title" style={{ padding: '0 10px', color: '#2F3031' }}>
                {iff(isEmptyStr(props.farmId), t('Edit Property'), t('Create Property'))}
              </PageHeader>
            </StyledHeader>
            <FarmContainer>
              <Form layout={'vertical'} className='login-form' onChange={() => fieldTouched()}>
                <Form.Item label={t('Name')}>
                  {getFieldDecorator('name', {
                    rules: [
                      { required: true, message: t('Please enter Name') },
                      {
                        max: AppConstants.VALIDATIONS.NAME_MAX_LENGTH,
                        message: t(`Name code cannot be longer than {{MAX}} characters`, {
                          MAX: AppConstants.VALIDATIONS.NAME_MAX_LENGTH
                        })
                      },
                      { whitespace: true, message: t('Field cannot have spaces') }
                    ]
                  })(
                    <StyledInput
                      placeholder={t('Enter Property Name')}
                      name='name'
                      size='large'
                      autoFocus
                      data-testid="property-name"
                    />
                  )}
                </Form.Item>
                <Row type='flex' justify='space-between'>
                  <Col span={11}>
                    <Form.Item label={`${t('Zip/Postal Code')}(${t('optional')})`}>
                      {getFieldDecorator(apiParams.zipCode, {
                        rules: [
                          {
                            max: AppConstants.VALIDATIONS.ZIP_CODE_MAX_LENGTH,
                            message: t(`Zip code cannot be longer than {{MAX}} characters`, {
                              MAX: AppConstants.VALIDATIONS.ZIP_CODE_MAX_LENGTH
                            })
                          },
                          {
                            pattern: new RegExp(/^[a-zA-Z0-9 \-/]+$/g),
                            message: t('Allow numbers, spaces and text only')
                          },
                          { whitespace: true }
                        ]
                      })(
                        <StyledInput
                          placeholder={`${t('Enter Zip/Postal Code')}`}
                          size='large'
                          style={{ textTransform: 'capitalize' }}
                          data-testid="property-zip"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={t('Country')}>
                      {getFieldDecorator('country', {
                        rules: [
                          { required: true, message: t('Please enter Country') },
                          { whitespace: true },
                          {
                            max: AppConstants.VALIDATIONS.COUNTRY_MAX_LENGTH,
                            message: t(`Country cannot be longer than {{MAX}} characters`, {
                              MAX: AppConstants.VALIDATIONS.COUNTRY_MAX_LENGTH
                            })
                          }
                        ]
                      })(
                        <StyledInput
                          placeholder={t('Enter country')}
                          size='large'
                          style={{ textTransform: 'capitalize' }}
                          data-testid="property-country"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' justify='space-between'>
                  <Col span={11}>
                    <Form.Item label={`${t('State')}(${t('optional')})`}>
                      {getFieldDecorator('state', {
                        rules: [
                          {
                            max: AppConstants.VALIDATIONS.STATE_MAX_LENGTH,
                            message: t(`State cannot be longer than {{MAX}} characters`, {
                              MAX: AppConstants.VALIDATIONS.STATE_MAX_LENGTH
                            })
                          },
                          { whitespace: true }
                        ]
                      })(
                        <StyledInput
                          placeholder={t('Enter state')}
                          size='large'
                          style={{ textTransform: 'capitalize' }}
                          data-testid="property-state"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`${t('City')}(${t('optional')})`}>
                      {getFieldDecorator('city', {
                        rules: [
                          { whitespace: true },
                          {
                            max: AppConstants.VALIDATIONS.CITY_MAX_LENGTH,
                            message: t(`City cannot be longer than {{MAX}} characters`, {
                              MAX: AppConstants.VALIDATIONS.CITY_MAX_LENGTH
                            })
                          }
                        ]
                      })(
                        <StyledInput
                          placeholder={t('Enter city')}
                          size='large'
                          style={{ textTransform: 'capitalize' }}
                          data-testid="property-city"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label={`${t('Address')}(${t('optional')})`}>
                  {getFieldDecorator('address', {
                    rules: [
                      {
                        max: AppConstants.VALIDATIONS.ADDRESS_MAX_LENGTH,
                        message: t(`Address cannot be longer than {{MAX}} characters`, {
                          MAX: AppConstants.VALIDATIONS.ADDRESS_MAX_LENGTH
                        })
                      }
                    ]
                  })(
                    <StyledInput
                      placeholder={t('Enter Address')}
                      size='large'
                      style={{ textTransform: 'capitalize' }}
                      data-testid="property-address"
                    />
                  )}
                </Form.Item>
                <Row type='flex' justify='space-between'>
                  <Col span={11}>
                    <Form.Item label={t('Latitude')}>
                      {getFieldDecorator('lat', {
                        rules: [
                          {
                            required: true,
                            message: t('Please enter Latitude')
                          },
                          {
                            pattern: /^([-+]?)(\d{1,3}(\.\d{1,8})?)$/,
                            message: t('Please enter valid Latitude')
                          }
                        ]
                      })(<StyledInput placeholder='00`' name='lat' size='large' type='number' data-testid="property-lat" />)}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={t('Longitude')}>
                      {getFieldDecorator('long', {
                        rules: [
                          {
                            required: true,
                            message: t('Please enter Longitude')
                          },
                          {
                            pattern: /^([-+]?)(\d{1,3}(\.\d{1,8})?)$/,
                            message: t('Please enter valid Longitude')
                          }
                        ]
                      })(<StyledInput placeholder='00`' name='long' size='large' type='number' data-testid="property-long" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <StyledAlert
                  message={t('Click on the map to update the latitude and longitude')}
                  type='info'
                  data-testid="property-note"
                />
                <Row type='flex' justify='end' align='bottom' gutter={['20']}>
                  <Col span={7} style={{ marginTop: '10px' }}>
                    <StyledDiscardButton
                      style={{ width: '100%' }}
                      type='secondary'
                      onClick={onBackButtonClicked}
                      size='large'
                      data-testid="property-cancel-btn">
                      {iff(isEmptyStr(props.farmId), t('Discard'), t('Cancel'))}
                    </StyledDiscardButton>
                  </Col>
                  {iff(
                    isEmptyStr(props.farmId),
                    <Col style={{ marginTop: '10px' }}>
                      <Button data-testid="property-edit-btn" size='large' className='btn-orange' onClick={handleEditFieldsClick}>
                        {t('Edit fields')}
                      </Button>
                    </Col>
                  )}
                  <Col style={{ marginTop: '10px' }}>
                    <StyledSaveButton
                      htmlType='button'
                      size='large'
                      onClick={onFormSubmit}
                      disabled={
                        !props.form.getFieldValue('name') ||
                        Object.values(props.form.getFieldsError()).some((el) => el !== undefined)
                      }
                      data-testid="property-save-btn">
                      {iff(isEmptyStr(props.farmId), t('Save changes'), t('Save and add fields'))}
                    </StyledSaveButton>
                  </Col>
                </Row>
                <ConfirmModal
                  data-testid="cancel-confirmation-property-modal"
                  visible={showModal}
                  title={iff(
                    props.farmId === null,
                    t('Cancel property creation'),
                    t('Cancel property edition')
                  )}
                  onOk={() => {
                    setShowModal(false);
                    if (isEditClicked) {
                      redirectToEditFieldsPage();
                    } else {
                      redirectToPropertiesPage();
                    }
                  }}
                  onCancel={() => {
                    setShowModal(false);
                    setIsEditClicked(false);
                  }}>
                  <p data-testid="cancel-property-modal-title">{t('All of your progress will be lost.')}</p>
                  <p>{t('Are you sure you want to cancel?')}</p>
                </ConfirmModal>
              </Form>
            </FarmContainer>
          </Col>
          <Col span={16}>
            <Maps
              focusLat={Number(props.form.getFieldValue('lat') || DEFAULT_LATLONG)}
              focusLong={Number(props.form.getFieldValue('long') || DEFAULT_LATLONG)}
              isDrawable={false}
              zoom={false}
              geoJsons={fields}
              reload={true}
              onMapClicked={(val, event) => {
                if (event) {
                  event.stopPropagation();
                }
                props.form.setFieldsValue({
                  lat: val.lat,
                  long: val.lng
                });
                let geocoder = new window.google.maps.Geocoder();
                let latlng = {
                  lat: Number(val.lat),
                  lng: Number(val.lng)
                };
                geocoder.geocode({ location: latlng }, function (results, status) {
                  if (status === 'OK') {
                    setAddressToForm(results[0]);
                  }
                });
              }}
            />
          </Col>
        </Row>
        <ErrorModal
          visible={!!errorMessage}
          message={errorMessage}
          okText={t('Ok')}
          onOk={() => {
            setErrorMessage('');
          }}
        />
      </div>
    )
  });
};

CreateEditFarmComponent.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func
  }).isRequired,
  property: PropTypes.object,
  success: PropTypes.bool,
  farmId: PropTypes.string
};

CreateEditFarmComponent.defaultProps = {
  property: null,
  success: false,
  farmId: null
};
export const WrapperCreateEditFarmComponent = Form.create({ name: 'CreateEditProperty' })(
  CreateEditFarmComponent
);
