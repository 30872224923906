import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Upload, Row, Col, Button, PageHeader, List, Icon } from 'antd/lib';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import PropTypes from 'prop-types';

import { iff } from '../../utils/iff';
import CloseIcon from '../../public/close.svg';
import CheckIcon from '../../public/check.svg';
import FindFileIcon from '../../public/find-file.svg';
import DragAndDropIcon from '../../public/drag-and-drop.svg';
import { DragAndDrop } from './DragAndDrop';
import { Maps } from '../Maps/Maps';
import { UploadContainer, FileListContainer, ButtonContainer } from './UploadShapeFiles.style.js';
import { PageLayout } from '../Layout/page-layout';
import { Loader } from '../Loader/Loader';
import { StyledHeader } from '../landingFarm/CreateEditFarmComponent.style';
import { getHeaders } from '../../utils/common-methods';
import { request } from '../../utils/axios';
import { history, URL_CONSTANTS } from '../../utils/history';
import { getAreaInHectareByGeoJson } from '../../utils/getAreaInAcreByGeoJson';
import { getFieldsByPropertyId } from '../../actions/fields';
import { getPropertyDetailsById, fetchOrgs } from '../../actions';
import { getLocationFromBrowser } from '../../utils/location';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { getErrorMessageByCode } from '../../utils/getErrorMessageByCode';
import { ConfirmModal } from '../common/ConfirmModal';
import { getOrgsData } from '../../selectors/organizationSelectors';
import { AmplitudeInstance } from '../../utils/amplitude';

const DEFAULT_LATLONG = 10.0;
const ACCEPT_EXT = ['.kml', '.shp', '.prj', '.dbf', '.shx'];

let fileList = [];
const getFileName = (fileFullName = '') => {
  const arr = fileFullName.split('.');
  return arr[0];
};

const getExtension = (fileFullName = '') => {
  const arr = fileFullName.split('.');
  return `.${arr[arr.length - 1]}`;
};
let currentOrgDetails = {};

const UploadShapeFiles = (props) => {
  const [newFields, setNewFields] = useState({});
  const [existingFields, setExistingFields] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lat, setLat] = useState(DEFAULT_LATLONG);
  const [long, setLong] = useState(DEFAULT_LATLONG);
  const [isValid, setIsValid] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { t } = useTranslation();

  const setFileList = async (f) => {
    fileList = f;
    let shapeObj = {};
    for (let i in fileList) {
      const file = fileList[i];
      const fileFullName = file.name;
      const name = getFileName(fileFullName);
      if (!shapeObj[name] || shapeObj[name].length === 0) {
        shapeObj[name] = [];
      }
      let updatedNewFile = file;
      if (getExtension(file.name) === '.kml') {
        updatedNewFile = await getUpdatedShapeFile(file);
      }
      shapeObj[name].push(updatedNewFile);
    }
    setIsValid(!isEmpty(shapeObj));
    setNewFields(shapeObj);
  };

  /**
   * Removes the extra value zero in lat-long co-ordinates
   * @param file
   * returns the new file object without extra zero in lat-long co-ordinates
   */
  const getUpdatedShapeFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (fileLoadedEvent) => {
        let textFromupLoadedFile = fileLoadedEvent.target.result;
        const beforeCoordinatesAndCoordinates = textFromupLoadedFile.split('<coordinates>');
        const beforeCoordinates = beforeCoordinatesAndCoordinates[0];
        let updatedTextFromupLoadedFile = beforeCoordinates;
        for (let i = 0; i < beforeCoordinatesAndCoordinates.length - 1; i++) {
          const coordinatesAndafterCoordinates = beforeCoordinatesAndCoordinates[i + 1].split('</coordinates>')
          let coordinates = coordinatesAndafterCoordinates[0];
          const afterCoordinates = coordinatesAndafterCoordinates[1];
          coordinates = coordinates.replace(/\n/g, ' ');
          const latlongsArray = coordinates.split(' ');
          let updatedlatlongsArray = [];

          latlongsArray.map(latlong => {
            let updatedLatlong = latlong.trim();
            if (updatedLatlong) {
              const latlongArray = updatedLatlong.split(',');
              if (latlongArray.length > 2) {
                latlongArray.pop();
                updatedLatlong = latlongArray.join();
                updatedlatlongsArray.push(updatedLatlong);
              } else if (updatedLatlong.length) {
                updatedlatlongsArray.push(updatedLatlong);
              }
            }
          })
          updatedTextFromupLoadedFile = updatedTextFromupLoadedFile + `\n<coordinates>${updatedlatlongsArray.join(' ')}</coordinates>\n${afterCoordinates}`;
        }

        const textToSaveAsBlob = new Blob([updatedTextFromupLoadedFile], { type: file.type });
        const newfile = new File([textToSaveAsBlob], file.name, { lastModified: new Date() });
        resolve(newfile);
      };
      reader.onerror = (err) => {
        console.log('File reading error: ', err);
        reject(err);
      };
      reader.readAsText(file, 'UTF-8');
    });
  };

  const redirectToCreateField = () => {
    if (props.location.state && props.location.state.addNewFieldFrom === 'MapsContainer') {
      history.push(
        URL_CONSTANTS.LANDING_FARM({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        })
      );
    } else {
      history.push(
        URL_CONSTANTS.CREATE_FIELDS({
          orgId: props.match.params.id,
          farmId: props.match.params.farmId
        })
      );
    }
  };

  const onBackClick = () => {
    if (isEmpty(newFields)) {
      redirectToCreateField();
    } else {
      setShowConfirmModal(true);
    }
  };

  const validateFile = (files, file) => {
    let fileListArray = [...files];
    if (!file.name || ACCEPT_EXT.indexOf(getExtension(file.name)) < 0) {
      return fileListArray;
    }
    let isDuplicate = false;
    fileListArray = fileListArray.map((f) =>
      iff(
        f.name === file.name,
        () => {
          isDuplicate = true;
          return file;
        },
        f
      )
    );
    if (!isDuplicate) {
      fileListArray.push(file);
    }
    return fileListArray;
  };

  useEffect(() => {
    const getDetails = async () => {
      const propertyRes = await getFieldsByPropertyId(props.match.params.farmId);
      let allFieldsRes = propertyRes.data.content || [];
      setExistingFields(allFieldsRes);
    };

    props.fetchOrgs().then((response) => {
      const data = get(response, 'data.content');
      currentOrgDetails = (data || []).find((a) => a.id === props.match.params.id) || {};
      getDetails();
    });

    (async () => {
      const propId = props.match.params.farmId;
      const propertyRes = await getPropertyDetailsById(propId);
      const coordinates = get(propertyRes.data, 'reference_point.coordinates');
      if (isEmpty(coordinates)) {
        (async () => {
          try {
            const location = await getLocationFromBrowser();
            setLong(location.longitude);
            setLat(location.latitude);
          } catch (err) {
            setErrorMessage(get(err, 'message'));
          }
        })();
      } else {
        setLat(coordinates[0] || DEFAULT_LATLONG);
        setLong(coordinates[1] || DEFAULT_LATLONG);
      }
    })();

    return () => {
      setFileList([]);
    };
  }, [props.match]);

  const uploadProps = {
    showUploadList: false,
    multiple: true,
    accept: ACCEPT_EXT.join(','),
    beforeUpload: (file) => {
      let fileListArray = [...fileList];
      fileListArray = validateFile(fileListArray, file);
      setFileList(fileListArray);
      return false;
    },
    fileList
  };

  const handleDrop = (fileParams) => {
    let fileListArray = [...fileList];
    for (let file of fileParams) {
      fileListArray = validateFile(fileListArray, file);
    }
    setFileList(fileListArray);
  };

  const getGeoJSON = (data) =>
    request({
      method: 'post',
      url: `/v2/utils/geo/as-geojson`,
      headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
      data
    });

  const onSaveButtonClicked = () => {
    let promArr = [];
    if (isEmpty(newFields)) {
      return;
    }
    for (let i in newFields) {
      const data = new FormData();
      for (let file of newFields[i]) {
        data.append('file', file);
      }
      promArr.push(getGeoJSON(data));
    }
    AmplitudeInstance.onSaveInAddNewFieldUsingShapeFile();
    setLoader(true);
    Promise.all(promArr)
      .then((success) => {
        setLoader(false);
        const geoJsons = [];
        for (let i in success) {
          const res = success[i];
          for (let j in res.data.features) {
            let feature = res.data.features[j];
            if (feature.geometry.type === 'MultiPolygon') {
              feature.geometry = {
                ...feature.geometry,
                type: 'Polygon',
                coordinates: feature.geometry.coordinates[0]
              };
            }
            geoJsons.push({
              ...feature,
              id: uuidv1(),
              name: Object.keys(newFields)[i] + iff(j > 0, `_${j}`, ''),
              adjustedSize: getAreaInHectareByGeoJson(feature)
            });
          }
        }
        history.push({
          pathname: URL_CONSTANTS.CREATE_FIELD_SHAPE_FILE_LIST({
            orgId: props.match.params.id,
            farmId: props.match.params.farmId
          }),
          state: {
            geoJsons,
            addNewFieldFrom: props.location.state?.addNewFieldFrom
          }
        });
      })
      .catch((err) => {
        setLoader(false);
        setErrorMessage(getErrorMessageByCode(get(err, 'response.data.code')));
      });
  };
  return PageLayout({
    content: (
      <>
        {iff(loader === true, <Loader />)}
        <DragAndDrop handleDrop={handleDrop}>
          <Row>
            <Col span={8}>
              <StyledHeader>
                <Icon
                  data-testid="uploadShapeFile-back-btn"
                  onClick={onBackClick}
                  className='back-button'
                  type='left'
                  style={{ fontSize: '14px' }}
                />

                <PageHeader>
                  <p style={{ color: 'grey', display: 'inline' }}>{t('Create fields')}</p>
                  {` • `}
                  {t('Upload file')}
                </PageHeader>
              </StyledHeader>
              <UploadContainer>
                <div className='header' data-testid="upload-file-page-title">
                  {t(`Upload the files containing field's shapes and names.`)}
                </div>
                <Row className='ant-upload-drag'>
                  <Col span={12}>
                    <div className='ant-upload-text drag-and-drop-file-here'>
                      <img src={DragAndDropIcon} alt="DragAndDropIcon" />
                      <div data-testid="drag-and-drop-title" className='title'>{t('Drag and drop')}</div>
                      <div className='desc'>
                        {t('Drag and drop your files anywhere on the screen')}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <Upload {...uploadProps}>
                      <div className='ant-upload-text file-uploader'>
                        <img src={FindFileIcon} alt="FindFileIcon" />
                        <div data-testid="find-file-title" className='title'>{t('Find file')}</div>
                        <div className='desc'>{t('Find the files on your computer')}</div>
                      </div>
                    </Upload>
                  </Col>
                </Row>
                <FileListContainer>
                  <List
                    dataSource={Object.keys(newFields)}
                    split={false}
                    renderItem={(i) => {
                      const file = newFields[i];
                      return (
                        <List.Item>
                          <List.Item.Meta
                            avatar={
                              <div
                                className={`icon-container ${iff(
                                  file.isInValid === true,
                                  'error',
                                  'success'
                                )}`}
                                data-testid="item-image">
                                {iff(
                                  file.isInValid === true,
                                  <img alt="" src={CloseIcon} />,
                                  <img alt="" src={CheckIcon} />
                                )}
                              </div>
                            }
                            title={i}
                            description={
                              <div className='file-ext' data-testid="item-desc">
                                {map(file, (f, index) => (
                                  <div key={index}>
                                    {iff(
                                      file.isInValid === true,
                                      <div>{t('Invalid file')}</div>,
                                      getExtension(f.name)
                                    )}
                                  </div>
                                ))}
                              </div>
                            }
                          />
                          <div>
                            <input type="button"
                              onClick={() => {
                                let files = fileList.filter((f) => {
                                  const xx = !file.find((c) => {
                                    return c.name === f.name;
                                  });
                                  return xx;
                                });
                                setFileList(files);
                              }}
                              className='delete-icon'
                              data-testid="item-delete-btn">
                            </input>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </FileListContainer>
                <ButtonContainer>
                  <Button
                    htmlType='submit'
                    className='btn-green'
                    size='large'
                    disabled={!isValid}
                    onClick={onSaveButtonClicked}
                    data-testid="save-btn">
                    {t('Save and add fields')}
                  </Button>
                  <ErrorModal
                    visible={!!errorMessage}
                    message={errorMessage}
                    okText={t('Ok')}
                    onOk={() => {
                      setErrorMessage('');
                    }}
                  />
                  <ConfirmModal
                    visible={showConfirmModal}
                    title={t('Cancel Upload Shape files')}
                    onOk={() => {
                      setShowConfirmModal(false);
                      redirectToCreateField();
                    }}
                    onCancel={() => setShowConfirmModal(false)}>
                    <p>{t('All of your progress will be lost.')}</p>
                    <p>{t('Are you sure you want to cancel?')}</p>
                  </ConfirmModal>
                </ButtonContainer>
              </UploadContainer>
            </Col>
            <Col span={16}>
              <Maps geoJsons={existingFields} focusLat={lat} focusLong={long} isDrawable={false} />
            </Col>
          </Row>
        </DragAndDrop>
      </>
    )
  });
};

UploadShapeFiles.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  }),
  orgs: PropTypes.array
};

const mapStateToProps = (state) => ({
  orgs: getOrgsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs())
});

export const UploadShapeFilesComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadShapeFiles);
