/**
 * App Loader : Before Loading App in the dom, we need to load few meta data in our system.
 * App Loader will be used to control the sequence in which things are loaded.
 * 1. We need to load language resources from Google before we start the app.
 * 2. We need to determine if user is logged in or not.
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import get from 'lodash/get';

import { history, publicItems, URL_CONSTANTS } from './utils/history';
import { request } from './utils/axios';
import { getParamValue } from './utils/getUrlParamsByName';
import { getHeaders, isAccessTokenAvailable } from './utils/common-methods';
import langData from './getLangData.json';
import { AmplitudeInstance } from './utils/amplitude';

class AppLoader {
  constructor() {
    this.metaData = {};
  }
  getMetaData() {
    return this.metaData;
  }
  initI18n() {
    return i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: langData,
        lng: localStorage.getItem('i18nextLng') || 'es',
        fallbackLng: {
          'en-GB': ['en'],
          'en-US': ['en'],
          'fr-FR': ['fr'],
          default: ['es']
        },
        debug: process.env.node_env !== 'production',
        keySeparator: false, // we use content as keys
        interpolation: {
          escapeValue: false
        }
      });
  }
  isUserLoggedIn() {
    let self = this;
    return new Promise((resolve) => {
      setTimeout(() => {
        self.metaData.isUserLoggedIn = true;
        resolve();
      }, 1000);
    });
  }
  async redirectAsPerLoggedInStatus() {
    const currentLocation = history.location.pathname;
    const session_idIndex = location.href.indexOf('session_id=');
    if (session_idIndex > 0) {
      const session_id = location.href.substr(session_idIndex + 11, 32);
      localStorage.setItem('session_id', session_id);
      try {
        const response = await request({
          method: 'get',
          url: `${process.env.MZ_API_URL}/sessions?session_id=${session_id}`
        });
        if (response.data) {
          AmplitudeInstance.onLoginFromNidera({
            session_id: session_id,
            email: response.data.email
          });
          localStorage.setItem('tokens', JSON.stringify(response.data));
          const fetchOrgsRes = await request({
            method: 'get',
            url: `/v2/orgs`,
            headers: getHeaders()
          });
          const orgs = get(fetchOrgsRes, 'data.content') || [];
          history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: orgs[0].id }));
          return false;
        } else {
          throw new Error('Invalid session_id');
        }
      } catch (err) {
        console.log('session_id error: ', err);
        if (![...publicItems, '/terms-and-conditions'].includes(currentLocation)) {
          location.href = `/login?ref=${btoa(currentLocation)}`;
          return true;
        }
      }
    }

    if (isAccessTokenAvailable()) {
      try {
        const response = await request({
          method: 'get',
          url: `/v2/orgs`,
          headers: getHeaders()
        });
        if (publicItems.includes(currentLocation)) {
          const orgs = get(response, 'data.content', []);
          const querInviteId = getParamValue('inviteId');
          const queruserEmail = getParamValue('userEmail');
          if (querInviteId && queruserEmail && currentLocation === '/sign-up-2') {
            return false;
          } else if (orgs.length > 0) {
            location.href = `/landing-organization/${orgs[0].id}`;
          } else {
            location.href = '/hall-of-org';
          }
          return true;
        }
      } catch (err) {
        console.error(err);
        if (![...publicItems, '/terms-and-conditions'].includes(currentLocation)) {
          location.href = `/login?ref=${btoa(currentLocation)}`;
          return true;
        }
      }
    }
    return false;
  }
  async load() {
    const redirectStatus = await this.redirectAsPerLoggedInStatus();
    if (redirectStatus) {
      return redirectStatus;
    }
    await this.initI18n();
  }
}

export { AppLoader };
