import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

const Themes = {
  managementZoneTheme: {
    colors: {
      titleColor: '#707374',
      inputBackground: '#F5F8FC',
      inputBorder: '#E8EAED',
      pageBackgroundSecondary: '#E5E5E5',
      pageBackgroundPrimary: '#FFFFFF',
      primary: '#00B5D8',
      secondary: '#F0F3F7',
      btnGreen: '#AAB400',
      fontColor: '#00A0BE',
      fontSecondaryColor: '#2F3031',
      focusRectangleColor: '#aab400',
      ErrorColor: 'red',
      // newly added items.
      buttonBackground: '#aab400',
      butonColor: '#fff',
      buttonHoverBgColor: '#F2F4F6',
      buttonHoverColor: '#25cce6',
      buttonHoverBorder: '1px solid #25cce6',
      inputFocusBorder: '1px solid #00B5D8',
      selectBoxshadowColor: 'rgba(0, 181, 216, 0.2)',
      saveAndAddFildBgColor: '#AAB400',
      saveAndAddFildBgHoverColor: ' #749204',
      backArrowBgColor: '#00a0be',
      backArrowColor: '#00a0be',
      propertiesCardBgColor: '#088da5',
      propertiesCardBgImage: 'linear-gradient(#088da5,#33b3cb)',
      rectangleBgColor: '#00a0be',
      scrollbarThumbBgColor: '#00B5D8',
      headerBorderBgColor: '#bfe1ea',
      sideBarSpinColor: '#00B5D8',
      buttonHoverBorderColor: '#00a0be',
      radioButtonCheckedBgColor: '#2a87d0',
      radioButtonBorderColor: '#2a87d0',
      datpickerSelectedDateHoverBgColor: '#1d5d90',
      datepikerSelectedDateColor: '#fff',
      datepikerNavigationButtonOutlineColor: '#1475ff',
      sliderBoxShadow: '0 0 0 5px rgb(51 196 224 / 37%);',
      prevNextBttonBorder: '1px solid #C1C5C8',
      alertColor: '#00B5D8',
      alertBackground: '#F2F4F6'
    },
    font: {
      family: 'Rubik, sans-serif',
    },
    fontSizes: {
      small: '12px',
      medium: '14px',
      large: '22px',
      Xlarge: '27.7941px',
    },
  },
  nideraTheme: {
    colors: {
      titleColor: 'rgba(0, 0, 0, 0.87)',
      inputBackground: '#F5F8FC',
      inputBorder: '#E8EAED',
      pageBackgroundSecondary: '#E5E5E5',
      pageBackgroundPrimary: '#FFFFFF',
      primary: '#FFD100',
      secondary: '#F0F3F7',
      btnGreen: '#AAB400',
      fontColor: '#ffe156',
      fontSecondaryColor: '#2F3031',
      focusRectangleColor: '#aab400',
      ErrorColor: 'red',
      // newly added items.
      buttonBackground: '#424242',
      butonColor: '#fff',
      buttonHoverBgColor: '#FFD100',
      buttonHoverColor: 'rgba(0, 0, 0, 0.87)',
      buttonHoverBorder: 'none',
      inputFocusBorder: '1px solid #FFD100',
      selectBoxshadowColor: 'rgba(255, 209, 0, 0.39)',
      saveAndAddFildBgColor: '#424242',
      saveAndAddFildBgHoverColor: ' #FFD100',
      backArrowBgColor: '#FFD100',
      backArrowColor: 'rgba(0, 0, 0, 0.87)',
      propertiesCardBgColor: '#FFD100',
      propertiesCardBgImage: 'linear-gradient(#FFD100,#ffe882)',
      rectangleBgColor: '#ffda33',
      scrollbarThumbBgColor: '#ffda33',
      headerBorderBgColor: '#ffda33',
      sideBarSpinColor: '#efc60b',
      buttonHoverBorderColor: '#FFD100',
      radioButtonCheckedBgColor: '#FFD100',
      radioButtonBorderColor: '#424242',
      datpickerSelectedDateHoverBgColor: '#ffbc00',
      datepikerSelectedDateColor: 'rgba(0, 0, 0, 0.87)',
      datepikerNavigationButtonOutlineColor: '#FFD100',
      sliderBoxShadow: '0 0 0 5px rgb(255 209 0 / 20%)',
      prevNextBttonBorder: '1px solid #C1C5C8',
      alertColor: 'rgba(0,0,0,0.87)',
      alertBackground: '#FFD100'
    },
    font: {
      family: 'Titillium Web, sans-serif',
    },
    fontSizes: {
      small: '12px',
      medium: '14px',
      large: '22px',
      Xlarge: '27.7941px',
    },
  },
};

export const Theme = ({ themeName, children }) => (
  <ThemeProvider theme={Themes[themeName]}>{children}</ThemeProvider>
);

Theme.propTypes = {
  themeName: PropTypes.string.isRequired,
  children: PropTypes.object
};

export const toThemeHoc = (Component, themeName) => props => (
  <Theme themeName={themeName}>
    <Component {...props} />
  </Theme>
);
